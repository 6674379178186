import Vue from "vue";
import Router from "vue-router";

import ChangePortal from './views/Change';

import Home from "./views/Home.vue";
import Conferences from "./views/Conferences.vue";
import SingleConferences from "./views/Conferences-single.vue";
import scheme from "./views/scheme";
import sampling from "./views/sampling";
import cathedra from "./views/cathedra";
import question from "./views/question";
import calc from "./views/calc.vue";
import prod from "./views/production";
import prodEnergy from './views/productions-energy';
import instruction from "./views/instruction";
import greetings from "./views/greetings";
import algorithms from "./views/algorithms";
import healthy from "./views/healthy/healthy";
import bkm from "./views/bkm-review/bkm";
import secondConsultationBkm from "./views/bkm-review/second-consultation";
import firstConsultationBkm from "./views/bkm-review/first-consultation";
import abkm from "./views/abkm-review/abkm";
import firstConsultation from "./views/abkm-review/first-consultation";
import secondConsultation from "./views/abkm-review/second-consultation";

import colic from "./views/intestinal-colic/colic";
import firstConsultationColic from "./views/intestinal-colic/first-consultation";
import secondConsultationColic from "./views/intestinal-colic/second-consultation";

import constipation from "./views/constipation/constipation";
import firstConsultationConstipation from "./views/constipation/first-consultation";
import secondConsultationConstipation from "./views/constipation/second-consultation";

import vomiting from "./views/vomiting/vomiting";
import firstConsultationVomiting from "./views/vomiting/first-consultation";
import secondConsultationVomiting from "./views/vomiting/second-consultation";

import fg from "./views/fg/fg";
import diagnosticsFg from "./views/fg/diagnostics";
import calcDayNormFg from "./views/fg/calc-day-norm";

import library from "./views/library.vue";
import libraryItem from "./views/library-item.vue";

import legalClinic from './views/legal-clinic.vue';
import legalClinic2021 from './views/legal-clinic-2021.vue';
import allergy from './views/allergy.vue';

import comfortCards from './views/Cards.vue';
import comfortCards2 from './views/Cards2.vue';
import comfortCards3 from './views/Cards3.vue';
import comfortCards4 from './views/Cards4.vue';
import comfortCards2Old from './views/Cards2-old.vue';

//Clinical
import ClinicalHome from "./views/Clinical/Home";
import ClinicalMain from "./views/Clinical/Main";
import ClinicalAlgorithms from "./views/Clinical/Algorithms";
import ClinicalLibrary from "./views/Clinical/Library.vue";
import ClinicalInstructiom from './views/Clinical/Instruction';

import ClinicalLibraryItem from "./views/Clinical/Library/LibraryItem";

import AlgorithmZond from "./views/Clinical/Algorithms/Zond.vue";
import AlgorithmEnteral from "./views/Clinical/Algorithms/Enteral.vue";
import AlgorithmEnergyCalc from "./views/Clinical/Algorithms/EnergyCalc.vue";

import ClinicalProductCategories from "./views/Clinical/ProductCategories.vue";
import ClinicalProducts from "./views/Clinical/Products/Products.vue";
import ClinicalProduct from "./views/Clinical/Products/Product.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    //Clinical
    {
      path: "/clinical",
      name: "clinical",
      component: ClinicalHome,
      children: [
        {
          path: "",
          name: "main",
          component: ClinicalMain,
          meta: {
            menuPointName: "home",
          },
        },
        {
          path: "/clinical/instruction",
          name: "clinical-instruction",
          component: ClinicalInstructiom,
          meta: {
            menuPointName: "/clinical/instruction",
          },
        },
        {
          path: "/clinical/algorithms",
          name: "clinical-algorithms",
          component: ClinicalAlgorithms,
          meta: {
            menuPointName: "/clinical/algorithms",
          },
        },
        {
          path: "/clinical/algorithms/zond",
          name: "zond",
          component: AlgorithmZond,
          meta: {
            menuPointName: "/clinical/algorithms/",
          },
        },
        {
          path: "/clinical/algorithms/enteral",
          name: "enternal",
          component: AlgorithmEnteral,
          meta: {
            menuPointName: "/clinical/algorithms/",
          },
        },
        {
          path: "/clinical/algorithms/energy-calc",
          name: "energy-calc",
          component: AlgorithmEnergyCalc,
          meta: {
            menuPointName: "/clinical/algorithms/",
          },
        },
        {
          path: "/clinical/library",
          name: "clinicalLibrary",
          component: ClinicalLibrary,
          meta: {
            menuPointName: "/clinical/library",
          },
        },
        {
          path: "/clinical/library-item/:type/:id",
          name: "clinicalLibraryItem",
          component: ClinicalLibraryItem,
        },
        {
          path: "/clinical/product-categories",
          name: "product-categories",
          component: ClinicalProductCategories,
          meta: {
            menuPointName: "/clinical/products",
          },
        },
        {
          path: "/clinical/products/oral",
          name: "products",
          component: ClinicalProducts,
          meta: {
            menuPointName: "/clinical/products",
            category: "oral",
          },
        },
        {
          path: "/clinical/products/probe",
          name: "products",
          component: ClinicalProducts,
          meta: {
            menuPointName: "/clinical/products",
            category: "probe",
          },
        },
        {
          path: "/clinical/product/:category/:id",
          name: "product",
          component: ClinicalProduct,
          meta: {
            menuPointName: "/clinical/products",
          },
        },
      ],
    },
    // Pediatric
    // {
    //   path: "/",
    //   name: "home",
    //   component: Home,
    // },
    {
      path: "/",
      name: "change",
      component: ChangePortal,
    },
    {
      path: "/pediatrics",
      name: "home",
      component: Home,
    },
    {
      path: "/conferences",
      name: "Conferences",
      component: Conferences,
    },
    {
      path: "/single-conferences/:id",
      name: "SingleConferences",
      component: SingleConferences,
    },
    {
      path: "/scheme",
      name: "scheme",
      component: scheme,
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/calculator",
      name: "calc",
      component: calc,
    },
    {
      path: "/production/:id",
      name: "prod",
      component: prod,
    },
    {
      path: "/production-energy/:id",
      name: "prod-energy",
      component: prodEnergy,
    },
    {
      path: "/instruction",
      name: "instruction",
      component: instruction,
    },
    {
      path: "/algorithms",
      name: "algorithms",
      component: algorithms,
    },
    {
      path: "/algorithms/healthy",
      name: "healthy",
      component: healthy,
    },
    {
      path: "/algorithms/allergy",
      name: "bkm",
      component: bkm,
    },
    {
      path: "/algorithms/allergy/firstConsultation",
      name: "firstConsultationBkm",
      component: firstConsultationBkm,
    },
    {
      path: "/algorithms/allergy/secondConsultation",
      name: "secondConsultationBkm",
      component: secondConsultationBkm,
    },
    {
      path: "/algorithms/abkm",
      name: "abkm",
      component: abkm,
    },

    {
      path: "/algorithms/abkm/firstConsultation",
      name: "firstConsultation",
      component: firstConsultation,
    },
    {
      path: "/algorithms/abkm/secondConsultation",
      name: "secondConsultation",
      component: secondConsultation,
    },

    {
      path: "/algorithms/colic",
      name: "colic",
      component: colic,
    },

    {
      path: "/algorithms/intestinalcolic/firstConsultation",
      name: "firstConsultationColic",
      component: firstConsultationColic,
    },
    {
      path: "/algorithms/intestinalcolic/secondConsultation",
      name: "secondConsultationColic",
      component: secondConsultationColic,
    },

    {
      path: "/algorithms/constipation",
      name: "constipation",
      component: constipation,
    },

    {
      path: "/algorithms/constipation/firstConsultation",
      name: "firstConsultationConstipation",
      component: firstConsultationConstipation,
    },
    {
      path: "/algorithms/constipation/secondConsultation",
      name: "secondConsultationConstipation",
      component: secondConsultationConstipation,
    },

    {
      path: "/algorithms/vomiting",
      name: "vomiting",
      component: vomiting,
    },
    {
      path: "/algorithms/vomiting/firstConsultation",
      name: "firstConsultationVomiting",
      component: firstConsultationVomiting,
    },
    {
      path: "/algorithms/vomiting/secondConsultation",
      name: "secondConsultationVomiting",
      component: secondConsultationVomiting,
    },

    {
      path: "/algorithms/fg",
      name: "fg",
      component: fg,
    },
    {
      path: "/algorithms/fg/diagnostics",
      name: "diagnosticsFg",
      component: diagnosticsFg,
    },
    {
      path: "/algorithms/fg/calcDayNorm",
      name: "calcDayNormFg",
      component: calcDayNormFg,
    },

    {
      path: "/greetings",
      name: "greetings",
      component: greetings,
    },
    {
      path: "/pediatrics/sampling",
      name: "sampling",
      component: sampling,
    },
    {
      path: "/pediatrics/cathedra",
      name: "cathedra",
      component: cathedra,
    },
    {
      path: "/pediatrics/cathedra/question",
      name: "question",
      component: question,
    },
    {
      path: "/library",
      name: "library",
      component: library,
    },
    {
      path: "/library-item/:type/:id",
      name: "libraryItem",
      component: libraryItem,
    },
    {
      path: "/legal_clinic_2020",
      name: "legal_clinic_2020",
      component: legalClinic
    },
    {
      path: '/cards/comfort',
      name: 'comfortСards',
      component: comfortCards
    },
    {
      path: '/cards/fg',
      name: 'comfortСards2',
      component: comfortCards2
    },
    {
      path: '/cards/fg-2021',
      name: 'comfortСards2Old',
      component: comfortCards2Old
    },
    {
      path: '/cards/ar',
      name: 'comfortСards3',
      component: comfortCards3
    },
    {
      path: '/cards/n125',
      name: 'comfortСards4',
      component: comfortCards4
    },
    {
      path: '/cards/legal_clinic_2021',
      name: 'legal_clinic_2021',
      component: legalClinic2021
    },
    {
      path: '/cards/allergy',
      name: 'allergy',
      component: allergy
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0};
  },
});
